import React from 'react'
import { Card, CardContent, CardHeader, CardTitle } from '../../ui/card'

export const EmptyAssetChart = () => {
  return (
    <Card className="mx-auto flex h-full flex-col border border-gray-700 bg-transparent">
      <CardHeader className="p-4 text-white">
        <div className="flex justify-between">
          <div className="flex items-center gap-x-2">
            <CardTitle className="font-medium md:text-lg">
              Assets by Class
            </CardTitle>
          </div>
          <p className="text-sm font-normal text-gray-500">30d</p>
        </div>
      </CardHeader>
      <CardContent className="mx-auto my-auto flex animate-pulse flex-row gap-4 p-4">
        <div className="size-40 rounded-full bg-gray-800"></div>
        <div className="my-auto flex flex-col gap-2">
          <div className="flex items-center gap-x-1">
            <div className="size-4 rounded-full bg-gray-800"></div>
            <div className="flex flex-col gap-y-px">
              <div className="h-4 w-16 rounded-full bg-gray-500"></div>
              <div className="h-4 w-12 rounded-full bg-gray-700"></div>
            </div>
          </div>
          <div className="flex items-center gap-x-1">
            <div className="size-4 rounded-full bg-gray-800"></div>
            <div className="flex flex-col gap-y-px">
              <div className="h-4 w-16 rounded-full bg-gray-500"></div>
              <div className="h-4 w-12 rounded-full bg-gray-700"></div>
            </div>
          </div>
          <div className="flex items-center gap-x-1">
            <div className="size-4 rounded-full bg-gray-800"></div>
            <div className="flex flex-col gap-y-px">
              <div className="h-4 w-16 rounded-full bg-gray-500"></div>
              <div className="h-4 w-12 rounded-full bg-gray-700"></div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export const EmptyTxChart = () => {
  return (
    <Card className="mx-auto flex h-full flex-col border border-gray-700 bg-transparent">
      <CardHeader className="p-4 text-white">
        <div className="flex justify-between">
          <div className="flex items-center gap-x-2">
            <CardTitle className="font-medium md:text-lg">
              Transactions
            </CardTitle>
          </div>
          <p className="text-sm font-normal text-gray-500">30d</p>
        </div>
      </CardHeader>
      <CardContent className="mx-auto my-auto flex animate-pulse flex-row gap-4 p-4">
        <div className="size-40 rounded-full bg-gray-800"></div>
        <div className="my-auto flex flex-col gap-2">
          <div className="flex items-center gap-x-1">
            <div className="size-4 rounded-full bg-gray-800"></div>
            <div className="flex flex-col gap-y-px">
              <div className="h-4 w-16 rounded-full bg-gray-500"></div>
              <div className="h-4 w-12 rounded-full bg-gray-700"></div>
            </div>
          </div>
          <div className="flex items-center gap-x-1">
            <div className="size-4 rounded-full bg-gray-800"></div>
            <div className="flex flex-col gap-y-px">
              <div className="h-4 w-16 rounded-full bg-gray-500"></div>
              <div className="h-4 w-12 rounded-full bg-gray-700"></div>
            </div>
          </div>
          <div className="flex items-center gap-x-1">
            <div className="size-4 rounded-full bg-gray-800"></div>
            <div className="flex flex-col gap-y-px">
              <div className="h-4 w-16 rounded-full bg-gray-500"></div>
              <div className="h-4 w-12 rounded-full bg-gray-700"></div>
            </div>
          </div>
          <div className="flex items-center gap-x-1">
            <div className="size-4 rounded-full bg-gray-800"></div>
            <div className="flex flex-col gap-y-px">
              <div className="h-4 w-16 rounded-full bg-gray-500"></div>
              <div className="h-4 w-12 rounded-full bg-gray-700"></div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export const EmptyStatsChart = () => {
  return (
    <Card className="mx-auto flex h-full flex-col border border-gray-700 bg-transparent">
      <CardHeader className="p-4 text-white">
        <div className="flex justify-between">
          <div className="flex items-center gap-x-2">
            <CardTitle className="font-medium md:text-lg">
              Chain Metrics
            </CardTitle>
          </div>
          <p className="text-sm font-normal text-gray-500">30d</p>
        </div>
      </CardHeader>
      <CardContent className="flex animate-pulse flex-col gap-4 p-4 pt-0">
        <div className="align-start flex gap-x-4 pb-0">
          <div className="h-14 w-24 rounded-full bg-gray-800" />
          <div className="h-14 w-24 rounded-full bg-gray-800" />
        </div>
        <div className="h-24 w-full rounded-md border border-gray-700" />
      </CardContent>
    </Card>
  )
}

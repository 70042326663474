import React, { useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '../../hooks'
import { TopNavTitle } from './TopNavTitle'
import { TopNavMenu } from './TopNavMenu'
import clsx from 'clsx'

export const TopNav = ({
  show,
  data,
  setShow,
}: {
  show: string
  data: any
  setShow: React.Dispatch<React.SetStateAction<string>>
}) => {
  const wrapperRef = useRef(null)
  useOnClickOutside(wrapperRef, () => {
    setFadeIn(false)
    setTimeout(() => {
      setShow('')
    }, 150)
  })
  const [fadeIn, setFadeIn] = useState(false)

  useEffect(() => {
    if (show) {
      setFadeIn(true)
    } else {
      setFadeIn(false)
    }
  }, [show])

  return data?.dropdownLinks?.length > 0 ? (
    <div
      ref={wrapperRef}
      className={clsx(
        `z-50 mt-4 flex flex-col gap-8 bg-white lg:left-0 lg:rounded-lg lg:p-6 lg:shadow-standard`,
        `transition-all duration-150 ease-in-out`,
        {
          '-translate-y-px opacity-0': !fadeIn,
          'opacity-1 translate-y-0': fadeIn,
          'static lg:absolute': show,
          hidden: !show,
        },
      )}
    >
      <TopNavTitle
        title={data.dropdownTitle}
        content={data.dropdownSubtitle}
        link={data.linkto || ''}
        isHeader
        limitWidth
      />
      <TopNavMenu
        data={data.dropdownLinks}
        oneColumn
        oneLayer
        headerNoWrap={false}
        smallMargin
        limitWidth
      />
    </div>
  ) : null
}

import React from 'react'
import clsx from 'clsx'

export const CardTitle = ({
  title,
  darkImage = false,
  size,
}: {
  title: string
  darkImage: boolean
  size: string
}) => (
  <div
    className={clsx(
      `
      break-words
      text-5xl
      font-bold
      `,
      {
        'text-blue-100': darkImage,
        'text-gray-900': !darkImage,
        'text-center': size !== 'large',
        'text-center md:text-left': size === 'large',
      },
    )}
  >
    {title}
  </div>
)

import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '../../hooks'
import { TopNavTitle } from './TopNavTitle'
import { TopNavMenu } from './TopNavMenu'
import clsx from 'clsx'
import type { ImageType } from '../../../templates/Generic'

export const TopNavComplex = ({
  show,
  data,
  setShow,
}: {
  show: string
  data: {
    dropdownSection: {
      id: string
      title: string
      subtitle: string
      linkto: string
      icon: ImageType
      dropdownLinks: {
        id: string
        linkto: string
        title: string
        subtitle: string
      }[]
      dropdownGroups: {
        icon: ImageType
        id: string
        linkto: string
        title: string
        subtitle: string
        dropdownLinks: {
          id: string
          linkto: string
          title: string
          subtitle: string
        }[]
      }[]
    }[]
  }
  setShow: React.Dispatch<React.SetStateAction<string>>
}) => {
  const wrapperRef = useRef(null)
  useOnClickOutside(wrapperRef, () => {
    setTimeout(() => {
      setFadeIn(false)
    }, 1)
    setTimeout(() => {
      setShow('')
    }, 150)
  })
  const [fadeIn, setFadeIn] = useState(false)

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setFadeIn(true)
      }, 1)
    }
  }, [show])

  return data?.dropdownSection?.length > 0 ? (
    <div
      ref={wrapperRef}
      className={clsx(
        `z-50 mt-4 w-auto bg-white lg:left-0 lg:rounded-lg lg:p-4 lg:shadow-standard`,
        `transition-all duration-150 ease-in`,
        {
          '-translate-y-px opacity-0': !fadeIn,
          'translate-y-0 opacity-100': fadeIn,
          'static lg:absolute': show,
          hidden: !show,
        },
      )}
    >
      <div className="flex flex-col gap-2 lg:grid lg:w-max lg:grid-cols-2 lg:gap-3">
        {data.dropdownSection.map(s => (
          // section div
          <div
            key={s.id}
            className={clsx(
              'flex flex-grow flex-col justify-center gap-8 rounded-lg border-[1px] border-gray-300 p-5 lg:p-5',
              'only:col-span-2 only:mb-0 only:border-0 only:p-2',
              { [s.dropdownLinks.length > 0 ? 'row-span-2' : '']: true },
              {
                [s.dropdownLinks.length > 0 && s.icon
                  ? 'bg-provconnect-nav-bg-mini bg-cover bg-center'
                  : '']: true,
              },
              // 'last:mr-0 last:border-r-0 last:pr-0',
              // 'lg:border-r-solid lg:mr-8 lg:border-b-0 lg:border-r lg:border-r-gray-300 lg:pr-8',
            )}
          >
            <TopNavTitle
              title={s.title}
              content={s.subtitle}
              link={s.linkto || ''}
              isHeader
              icon={s.icon}
              limitWidth
              headerNoWrap={false}
            />
            {s.dropdownGroups.length > 0 ? (
              <div className="mt-6 flex flex-col gap-8 lg:flex-row">
                {s.dropdownGroups.map(g => (
                  <div key={g.id}>
                    <TopNavTitle
                      title={g.title}
                      content={g.subtitle}
                      link={g.linkto || ''}
                      icon={g.icon}
                      isSubheader
                      hasBorder
                    />

                    <TopNavMenu data={g.dropdownLinks} smallMargin />
                  </div>
                ))}
              </div>
            ) : null}

            {s.dropdownLinks.length > 0 && (
              <TopNavMenu
                data={s.dropdownLinks}
                oneColumn
                oneLayer
                headerNoWrap={false}
                smallMargin
                limitWidth
              />
            )}
          </div>
        ))}
      </div>
    </div>
  ) : null
}

import { ChartsApiResponse } from '../../lib/types'
import {
  formatNumber,
  formatMoneyOneDigit,
  formatNumberOneDigit,
} from '../../utils'
import {
  EmptyAssetChart,
  EmptyTxChart,
  EmptyStatsChart,
  PieChart,
  StackedBarChart,
} from './Components'
import React from 'react'
import { Carousel, CarouselContent, CarouselItem } from '../ui/carousel'

const assetsChart = ({
  chartsData,
}: {
  chartsData: ChartsApiResponse | null
}) => {
  const tvlSummaryChart = chartsData.tvlSummaryChart
  return (
    <PieChart
      title="Assets by Class"
      changeDirection={
        tvlSummaryChart.percentChange >= 0 ? 'positive' : 'negative'
      }
      changePercentage={tvlSummaryChart.percentChange}
      data={tvlSummaryChart.chartDataTypes}
      centerTitle="RWA TVL"
      centerData={formatMoneyOneDigit(
        tvlSummaryChart.chartDataTypes.reduce((acc, cur) => acc + cur.value, 0),
      )}
    />
  )
}

const txChart = ({ chartsData }: { chartsData: ChartsApiResponse | null }) => {
  const txSummaryChart = chartsData.txSummaryChart
  return (
    <PieChart
      title="Transactions"
      changeDirection={
        txSummaryChart.percentChange >= 0 ? 'positive' : 'negative'
      }
      changePercentage={txSummaryChart.percentChange}
      data={txSummaryChart.chartDataTypes}
      centerTitle="total TX"
      centerData={formatNumberOneDigit(
        txSummaryChart.chartDataTypes.reduce((acc, cur) => acc + cur.value, 0),
      )}
      valueFormatter={value => formatNumber(value)}
    />
  )
}

const settlementChart = ({
  chartsData,
}: {
  chartsData: ChartsApiResponse | null
}) => {
  let stackedBarChartData = chartsData?.stackedBarChartData
  return (
    <StackedBarChart
      data={stackedBarChartData.dailySettlementAndFees}
      title="Chain Metrics"
      callouts={[
        {
          title: 'Settlement Volume',
          changeDirection:
            stackedBarChartData.settlementPercentChange >= 0
              ? 'positive'
              : 'negative',
          changePercentage: stackedBarChartData.settlementPercentChange,
          value: formatMoneyOneDigit(
            stackedBarChartData.totalSettlementVolume,
            0,
          ),
        },
        {
          title: 'Fees in USD',
          changeDirection:
            stackedBarChartData.feesPercentChange >= 0
              ? 'positive'
              : 'negative',
          changePercentage: stackedBarChartData.feesPercentChange,
          value: formatMoneyOneDigit(stackedBarChartData.totalFeesInUsd),
        },
      ]}
    />
  )
}

export const PBChartsLoading = () => {
  return (
    <div className="bg-[#0A1828] py-4">
      <div className="max-w-page items-center justify-center gap-0 overflow-hidden px-6 md:mx-auto lg:px-6 xl:px-24">
        <Carousel
          opts={{
            align: 'start',
            loop: false,
          }}
        >
          <CarouselContent className="flex w-auto flex-row gap-3 md:gap-4 lg:gap-4 xl:-ml-8 xl:gap-6">
            <CarouselItem className="aspect-[1.33] basis-[100%] min-[500px]:aspect-[1.75] min-[600px]:aspect-[2] min-[700px]:aspect-[1.33] min-[732px]:basis-[48%] min-[1074px]:basis-[32%] xl:basis-1/3">
              <EmptyAssetChart />
            </CarouselItem>
            <CarouselItem className="aspect-[1.33] basis-[100%] min-[500px]:aspect-[1.75] min-[600px]:aspect-[2] min-[700px]:aspect-[1.33] min-[732px]:basis-[48%] min-[1074px]:basis-[32%] xl:basis-1/3">
              <EmptyTxChart />
            </CarouselItem>
            <CarouselItem className="aspect-[1.33] basis-[100%] min-[500px]:aspect-[1.75] min-[600px]:aspect-[2] min-[700px]:aspect-[1.33] min-[732px]:basis-[48%] min-[1074px]:basis-[32%] xl:basis-1/3">
              <EmptyStatsChart />
            </CarouselItem>
          </CarouselContent>
        </Carousel>
      </div>
    </div>
  )
}

export const PBCharts = ({
  chartsData,
}: {
  chartsData: ChartsApiResponse | null
}) => {
  return (
    <div className="bg-[#0A1828] py-4">
      <div className="max-w-page items-center justify-center gap-0 overflow-hidden px-6 md:mx-auto lg:px-6 xl:px-24">
        <Carousel
          opts={{
            align: 'start',
            loop: false,
          }}
        >
          <CarouselContent className="flex w-auto flex-row gap-3 md:gap-4 lg:gap-4 xl:-ml-8 xl:gap-6">
            <CarouselItem className="aspect-[1.33] basis-[100%] min-[500px]:aspect-[1.75] min-[600px]:aspect-[2] min-[700px]:aspect-[1.33] min-[732px]:basis-[48%] min-[1074px]:basis-[32%] xl:basis-1/3">
              {assetsChart({ chartsData })}
            </CarouselItem>
            <CarouselItem className="aspect-[1.33] basis-[100%] min-[500px]:aspect-[1.75] min-[600px]:aspect-[2] min-[700px]:aspect-[1.33] min-[732px]:basis-[48%] min-[1074px]:basis-[32%] xl:basis-1/3">
              {txChart({ chartsData })}
            </CarouselItem>
            <CarouselItem className="aspect-[1.33] basis-[100%] min-[500px]:aspect-[1.75] min-[600px]:aspect-[2] min-[700px]:aspect-[1.33] min-[732px]:basis-[48%] min-[1074px]:basis-[32%] xl:basis-1/3">
              {settlementChart({ chartsData })}
            </CarouselItem>
          </CarouselContent>
        </Carousel>
      </div>
    </div>
  )
}

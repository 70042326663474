import React from 'react'

export const ArrowUp = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    className={className}
  >
    <path
      d="M2.85711 7.09399H-3.05176e-05L4.99997 0.0939941L9.99997 7.09399H7.14283H2.85711Z"
      fill="#22C55E"
    />
  </svg>
)

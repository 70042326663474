import React from 'react'
import { ArrowDown, ArrowUp } from './icon'

export type ChangeDirectionType = 'positive' | 'negative'

export const ChangeDirection = ({
  changeDirection,
  changePercentage,
}: {
  changeDirection: ChangeDirectionType
  changePercentage: number
}) => (
  <div className="flex items-center gap-x-1">
    {changeDirection === 'positive' ? <ArrowUp /> : <ArrowDown />}
    <p
      className={
        changeDirection === 'positive'
          ? 'text-sm text-[#22C55E]'
          : 'text-sm text-[#FF2451]'
      }
    >
      {changePercentage}%
    </p>
  </div>
)

import React from 'react'

export const ArrowDown = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="8"
    viewBox="0 0 11 8"
    fill="none"
    className={className}
  >
    <path
      d="M7.47623 0.0939941H10.3334L5.33337 7.09399L0.333374 0.0939941H3.19052L7.47623 0.0939941Z"
      fill="#FF2451"
    />
  </svg>
)

import { type ComponentProps } from 'react'
import { Bar, BarChart, type TooltipProps } from 'recharts'
import {
  type NameType,
  type ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { Card, CardContent, CardHeader, CardTitle } from '../../ui/card'
import { type ChartConfig, ChartContainer, ChartTooltip } from '../../ui/chart'
import { formatMoneyOneDigit, formatPercent } from '../../../utils'
import { ChangeDirection } from './change-direction'
import React from 'react'

const chartConfig = {
  top: {
    label: 'Top',
    color: '#3D4B5C',
  },
  bottom: {
    label: 'Bottom',
    color: '#CCDE44',
  },
} satisfies ChartConfig

const Callout = ({
  title,
  changePercentage,
  changeDirection,
  value,
}: {
  title: string
  changePercentage: number
  changeDirection: 'positive' | 'negative'
  value: string
}) => (
  <div className="flex flex-col justify-between">
    <div className="flex items-start gap-x-2">
      <p className="text-xs text-gray-500 min-[431px]:text-base min-[732px]:text-xs min-[1309px]:text-base">
        {title}
      </p>
      <ChangeDirection
        changeDirection={changeDirection}
        changePercentage={changePercentage}
      />
    </div>
    <p className="text-xl font-extrabold text-white min-[431px]:text-4xl lg:text-2xl xl:text-4xl">
      {value}
    </p>
  </div>
)

const TooltipItem = ({
  type,
  value,
  percent,
}: {
  type: 'settle' | 'fee'
  value: number
  percent: number
}) => (
  <div className="flex items-center gap-x-1">
    <div
      className="size-4 rounded-full"
      style={{
        // This is silly, but for whatever reason all the available data isn't specified in the types from Recharts.
        // Probably because this level of customization isn't typical
        background:
          type === 'settle' ? 'var(--color-bottom)' : 'var(--color-top)',
      }}
    />
    <div className="flex flex-col gap-y-px pl-2">
      <p className="text-xs font-semibold text-black">
        {type === 'fee' ? 'Fees in USD' : 'Settlement Volume'}
      </p>
      <p className="text-xs text-gray-500">
        {`${formatMoneyOneDigit(value)} (${formatPercent(percent)})`}
      </p>
    </div>
  </div>
)

type PayloadType = { payload: { top: number; bottom: number } }

const CustomTooltip = ({
  payload,
  active,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length > 0) {
    const currentPayload: PayloadType = payload[0] as PayloadType
    const total = currentPayload.payload.top + currentPayload.payload.bottom
    return (
      <div className="custom-tooltip flex flex-col gap-2 rounded-md bg-white p-2">
        <TooltipItem
          type="settle"
          value={currentPayload.payload.bottom}
          percent={currentPayload.payload.bottom / total}
        />
        <TooltipItem
          type="fee"
          value={currentPayload.payload.top}
          percent={currentPayload.payload.top / total}
        />
      </div>
    )
  }

  return null
}

export const StackedBarChart = ({
  title,
  callouts,
  data,
}: {
  title: string
  callouts: ComponentProps<typeof Callout>[]
  data: { top: number; bottom: number }[]
}) => (
  <Card className="border border-gray-700 bg-transparent">
    <CardHeader className="px-4 pb-2 pt-4 text-white">
      <div className="flex justify-between">
        <CardTitle className="font-medium md:text-lg">{title}</CardTitle>
        <p className="text-sm font-normal text-gray-500">30d</p>
      </div>
    </CardHeader>
    <div className="flex gap-x-4 px-4 pb-0">
      {callouts.map(c => (
        <Callout key={c.title} {...c} />
      ))}
    </div>
    <CardContent className="px-4 pb-4">
      <ChartContainer config={chartConfig}>
        <BarChart accessibilityLayer data={data}>
          <defs>
            <linearGradient
              key="fillBottom"
              id="fillBottom"
              x1="0"
              y1="0"
              x2="1"
              y2="1"
            >
              <stop offset="0%" stopColor="#DEE897" stopOpacity={1} />
              <stop offset="100%" stopColor="#CCDE44" stopOpacity={1} />
            </linearGradient>
          </defs>
          <ChartTooltip
            content={<CustomTooltip />}
            cursor={{ stroke: 'black', strokeWidth: 1, fill: 'black' }}
          />
          <Bar dataKey="top" stackId="a" fill="#000" radius={[6, 6, 0, 0]} />
          <Bar
            dataKey="bottom"
            stackId="a"
            fill="url(#fillBottom)"
            radius={[6, 6, 0, 0]}
          />
        </BarChart>
      </ChartContainer>
    </CardContent>
  </Card>
)

import React from 'react'
import clsx from 'clsx'
import { IconLink } from '../Link'
import BlogAuthorSubscribe from './BlogAuthorSubscribe'
import { isEmpty } from '../../utils'
import { ImageType } from '../../templates/Generic'

export type BlogAuthorType = {
  author: {
    bio: string | null
    company: string | null
    guestContributor: boolean | null
    id: string
    showEmail: boolean
    image: ImageType | null
    name: string | null
    socialMediaLinks: Array<{
      id: string
      linkTo: string | null
      model: { apiKey: string | null } | null
      image: ImageType | null
    } | null> | null
    title: string | null
  }
}

export const BlogAuthor = ({
  author: {
    bio,
    company,
    showEmail,
    guestContributor,
    image,
    name,
    socialMediaLinks,
    title,
  },
}: BlogAuthorType) => {
  return (
    <div className="mx-auto w-full bg-grey-300 px-4 py-16 sm:px-28">
      <h2 className="mx-auto max-w-7xl text-3xl font-bold text-grey-900 sm:text-5xl">
        {guestContributor ? 'Community Contributor' : 'Author'}
      </h2>
      <div
        className={`
          mx-auto mt-12 grid
          max-w-7xl grid-cols-1 gap-8
          rounded-3xl bg-white
          px-4
          py-8 drop-shadow-lg
          sm:grid-cols-2 sm:gap-32
          sm:px-12 sm:py-20
          `}
      >
        {!isEmpty(image) && (
          <div className="mx-auto w-full max-w-sm self-center sm:w-auto sm:max-w-none">
            <img
              className="w-full rounded-3xl"
              src={image?.url || ''}
              alt={image?.alt || image?.filename || `${name}`}
            />
          </div>
        )}
        <div className={clsx({ 'col-span-full self-center': isEmpty(image) })}>
          <h3 className="text-center text-xl font-bold text-blue-700 sm:text-left sm:text-2xl">
            {name}
          </h3>
          {name !== company && (
            <h3 className="text-center text-xl text-grey-600 sm:text-left">
              {title}, {company}
            </h3>
          )}
          {socialMediaLinks && socialMediaLinks.length > 0 && (
            <div className="mt-2 flex items-center justify-center gap-4 sm:justify-start">
              {socialMediaLinks?.map(link =>
                link?.linkTo && link?.image?.url ? (
                  <IconLink
                    key={link.id}
                    linkTo={link.linkTo}
                    image={link.image}
                  />
                ) : null,
              )}
            </div>
          )}
          <p
            className={clsx('mt-8 text-base text-grey-700', {
              'my-8': guestContributor,
            })}
          >
            {bio}
          </p>
          {guestContributor && name && title && showEmail && (
            <BlogAuthorSubscribe name={name} title={title} />
          )}
        </div>
      </div>
    </div>
  )
}

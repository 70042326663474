import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { SmartLink } from '../../Link'

export const TopBarLogo = () => {
  const {
    datoCmsTopBar: { topBarLogo },
  } = useStaticQuery(graphql`
    {
      datoCmsTopBar {
        topBarLogo {
          image {
            url
          }
          linkTo
        }
      }
    }
  `)

  const logo = topBarLogo[0]

  return (
    <SmartLink linkTo={logo.linkTo}>
      <img
        className="mr-12 max-h-12 xl:mr-[4.5rem]"
        src={logo.image.url}
        alt={logo.image.alt || logo.image.filename || 'Provenance Logo icon'}
      />
    </SmartLink>
  )
}

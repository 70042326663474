import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { SmartLink } from '../Link'

/** Renders markdown using react-markdown, with custom components
 * to support lists and links opening in new tabs
 */
export const Markdown = ({ markdown }: { markdown: string }) => (
  <ReactMarkdown
    children={markdown}
    components={{
      br: ({ node, ...props }) => (
        <div className="mb-2">
        </div>
      ),
      h1: ({ node, ...props }) => (
        <p
          {...props}
          className="py-4 text-3xl font-bold text-gray-900 font-MaisonNeueExtended md:text-5xl"
        />
      ),
      h2: ({ node, ...props }) => (
        <p
          {...props}
          className="my-3 text-2xl font-bold text-gray-900"
        />
      ),
      h3: ({ node, ...props }) => (
        <p
          {...props}
          className="my-2 text-xl font-normal text-gray-800"
        />
      ),
      p: ({ node, ...props }) => (
        <p
          {...props}
          className="pb-2.5"
        />
      ),
      a: ({ node, ...props }) => (
        <SmartLink
          {...props}
          linkTo={props.href}
          className="inline underline"
        />
      ),
      // Remove ordered from props to avoid React warning
      ul: ({ node, ordered, ...props }) => (
        <ul className="pb-4 list-disc ps-10 pe-10" {...props} />
      ),
      li: ({ node, ordered, ...props }) => (
        <li className="pt-1.5" {...props} />
      ),
      ol: ({ node, ordered, ...props }) => (
        <ol className="pb-4 list-decimal ps-10 pe-10" {...props} />
      ),
    }}
    remarkPlugins={[remarkGfm]}
  />
)

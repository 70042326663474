import React from 'react'
import clsx from 'clsx'

export const Title = ({
  title,
  darkMode,
}: {
  title: string
  darkMode: boolean
}) => (
  <h2
    className={clsx(
      `pb-2 font-MaisonNeueExtended text-5xl font-bold text-black`,
      {
        'text-blue-100': darkMode,
      },
    )}
  >
    {title}
  </h2>
)

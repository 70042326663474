import {
  Activity,
  Clock,
  CreditCard,
  DollarSign,
  PiggyBank,
} from 'lucide-react'
import { formatMoney, truncateText } from '../../utils'
import { TickerData } from '../../lib/types'
import React from 'react'
import { Carousel, CarouselContent, CarouselItem } from '../ui/carousel'
import AutoScroll from 'embla-carousel-auto-scroll'

// Extract ticker type styles to a constant
const TICKER_STYLES = {
  Credit: { color: '#B2C5F0', Icon: CreditCard },
  Equity: { color: '#8BCBC8', Icon: DollarSign },
  Fund: { color: '#E9F2A6', Icon: PiggyBank },
  Exchange: { color: '#E9F2A6', Icon: PiggyBank },
} as const

// Simplify getTickerInfo function
const getTickerInfo = (f: TickerData) => {
  const style = TICKER_STYLES[f.type as keyof typeof TICKER_STYLES]
  if (!style) return null

  const { color, Icon } = style
  return (
    <div
      className="flex items-center gap-x-1 rounded-full border px-2 py-0"
      style={{ borderColor: color }}
    >
      <Icon className="size-4" style={{ color }} />
      <p className="text-sm" style={{ color }}>
        {f.type}
      </p>
    </div>
  )
}

// Extract activity header to a separate component
const ActivityHeader = () => (
  <div
    aria-label="Activity"
    className="flex min-h-full flex-col items-center justify-center gap-x-2 gap-y-0.5 border-r border-gray-700 bg-[#0A1828] bg-transparent px-4 text-white md:flex-row"
  >
    <Activity className="size-4 md:size-5" />
    <p className="text-xs font-medium leading-none lg:text-sm">Activity</p>
  </div>
)

export const PBTickerLoading = () => {
  return (
    <div className="max-w-screen flex border-b border-gray-700 bg-[#0A1828]">
      <div className="mx-auto flex w-auto max-w-page overflow-hidden">
        <ActivityHeader />
        <div className="no-scrollbar flex animate-pulse gap-4 px-2 py-2 md:px-4">
          <div className="h-8 w-48 rounded-full bg-gray-700" />
          <div className="my-auto size-1.5 rounded-full bg-[#3D4B5C]" />
          <div className="h-8 w-48 rounded-full bg-gray-700" />
          <div className="my-auto size-1.5 rounded-full bg-[#3D4B5C]" />
          <div className="h-8 w-48 rounded-full bg-gray-700" />
          <div className="my-auto size-1.5 rounded-full bg-[#3D4B5C]" />
          <div className="h-8 w-48 rounded-full bg-gray-700" />
          <div className="my-auto size-1.5 rounded-full bg-[#3D4B5C]" />
          <div className="h-8 w-48 rounded-full bg-gray-700" />
          <div className="my-auto size-1.5 rounded-full bg-[#3D4B5C]" />
          <div className="h-8 w-48 rounded-full bg-gray-700" />
        </div>
      </div>
    </div>
  )
}

export const PBTicker = ({ tickerData }: { tickerData: TickerData[] }) => {
  return (
    <div className="max-w-screen flex border-b border-gray-700 bg-[#0A1828]">
      <div className="mx-auto flex w-auto max-w-page overflow-hidden">
        <ActivityHeader />
        <Carousel
          style={{
            maskImage:
              'linear-gradient(to right, black 0%, black 5%, black 95%, transparent 100%)',
          }}
          opts={{
            align: 'start',
            loop: true,
            dragFree: true,
          }}
          className="no-scrollbar overflow-x-scroll px-2 py-2 md:px-4"
          plugins={[
            AutoScroll({
              speed: 0.4,
              startDelay: 0,
              stopOnMouseEnter: true,
              stopOnInteraction: false,
            }),
          ]}
        >
          <CarouselContent className="flex gap-1">
            {tickerData.map((f, idx) => (
              <CarouselItem
                className="flex basis-auto items-center"
                key={`${f.address} - ${idx}`}
              >
                <div className="item-center flex gap-x-2">
                  {getTickerInfo(f)}
                  <div className="text-nowrap">
                    <div className="flex gap-x-1">
                      <p className="w-fit text-nowrap text-sm font-medium leading-5 text-[#B2C5F0]">
                        {f.value ? formatMoney(f.value, 1) : '--'}
                      </p>
                      <p className="text-sm leading-5 text-white">
                        {truncateText(f.address || '', 4, 3)}
                      </p>
                    </div>
                    <p className="font-regular text-xs text-[#8C98A6]">
                      {f.description}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center gap-x-1 gap-y-1 pl-2 text-[#5F6E81]">
                  <Clock className="size-3" />
                  <p className="text-xs leading-none">{f.time}</p>
                </div>
                <div className="mx-4 size-1.5 rounded-full bg-[#3D4B5C]" />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
    </div>
  )
}

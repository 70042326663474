import React, { useState } from 'react'
import clsx from 'clsx'
import type { ButtonProps } from '../Button'
import { ButtonGroup } from '../TextAndContent/ButtonGroup'
import { Lottie } from './Lottie'
import { ThreeJs } from './ThreeJs'
import { Card } from '../ui/card'
import { Input, Message } from '../FormComponent/Components'
import { Button } from '../Button/Button'
import { useForm, Controller } from 'react-hook-form'
import { getCookie } from '../../utils'

export type HeaderDataProps = {
  id: string
  anchorTag: string
  bgGradientStart?: string
  bgGradientEnd?: string
  buttons: ButtonProps[]
  direction: 'horizontal' | 'vertical'
  image?: {
    url: string
    alt?: string
  }
  lottieFile: string
  title?: string
  subtitle?: string
  textGradientStart?: string
  textGradientEnd?: string
  threeJs?: string
  signupHeader?: boolean
}

const SignupForm = () => {
  const [status, setStatus] = useState('')
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const formId = '2fa37042-f48a-425a-b627-dcaf5a6661bb'

  const onSubmit = async (data: any) => {
    setStatus('pending')
    /* const ipRes = await fetch('https://jsonip.com/')
     const { ip: ipAddress } = await ipRes.json() */
    const hutk = getCookie('hubspotutk')
    const loc = location?.href

    const res = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/48233040/${formId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: Object.keys(data).map(key => ({
            name: key,
            value: data[key],
          })),
          context: {
            hutk,
            pageUri: loc,
            // Return page name as the last index of the url
            pageName: loc.substring(loc.lastIndexOf('/')),
            /* ipAddress, */
          },
        }),
      },
    )
    if (res.ok && res.status < 400) {
      setStatus('completed')
      reset()
    } else setStatus('failed')
  }

  const pending = status === 'pending'
  const completed = status === 'completed'
  const failed = status === 'failed'

  return (
    <Card className="my-auto flex h-min w-full flex-col gap-8 rounded-[6px] p-6">
      {completed ? (
        <div className="flex flex-col gap-4">
          <Message
            message="Thanks for submitting your email address.   We'll be back shortly with an update on your access to ProvConnect."
            success
          />
          <Message message="Best, ProvLabs team" success />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <h1 className="text-lg text-gray-700 font-regular">
            Request early access to ProvConnect
          </h1>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id={formId}
            className="flex gap-2 items-center w-full"
          >
            <Input
              register={register}
              name="email"
              type="email"
              required
              label="Email"
              className="flex h-[38px] w-full rounded-md border border-input bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
              placeholder="Email"
              errors={errors}
            />
            <Button
              rounded="rounded-[6px] before:rounded-[6px]"
              font="font-medium"
              btnType="light"
              size="small"
              type="submit"
              disabled={pending}
            >
              Get Early Access
            </Button>
          </form>
          <p className="text-sm text-gray-600 font-MaisonNeue">
            Your gateway to tokenizing assets, building and integrating
            applications, viewing your assets and Provenance Blockchain
            activity, and more.
          </p>
        </div>
      )}
      {failed && <Message failure />}
    </Card>
  )
}

export const Header = ({ data }: { data: HeaderDataProps[] }) => {
  return data.map(item => {
    const isHorizontal = item.direction === 'horizontal'
    const isVertical = item.direction === 'vertical'
    return (
      <div
        key={item.id}
        className={`
          bg-gradient-to-br from-5% to-95%
          from-${item.bgGradientStart || 'gray-300'}
          to-${item.bgGradientEnd || 'white'}
        `}
      >
        <div
          key={item.id}
          className={clsx('mt-28 pt-16 md:pt-24', {
            'grid grid-cols-1 place-items-center': !item.signupHeader,
            'flex flex-col gap-8 min-[1043px]:flex-row': item.signupHeader,
            'max-w-page gap-x-0 px-4 sm:grid-cols-2 md:mx-auto md:grid-cols-[55%_1fr] md:gap-x-16 md:px-24 xl:gap-x-32':
              isHorizontal,
            'gap-16 text-center': isVertical,
            'pb-8 md:pb-[4.5rem] lg:pb-32':
              (isVertical && !item.image?.url) || isHorizontal,
          })}
        >
          <div
            className={clsx({
              'flex flex-col col-span-2 gap-8 h-min md:col-span-1':
                isHorizontal,
              'px-4 max-w-page md:mx-auto md:px-24': isVertical,
            })}
          >
            <div className="flex flex-col gap-2">
              <h1
                className={clsx(
                  `inline-block bg-gradient-to-br from-15% to-70%`,
                  `bg-clip-text font-MaisonNeueExtended font-bold text-transparent xl:leading-snug`,
                  `from-${item.textGradientStart || 'blue-600'} to-${item.textGradientEnd || 'blue-500'}`,
                  {
                    'text-5xl xl:text-[4.575rem]': isHorizontal,
                    'text-4xl md:text-7xl xl:text-[4.625rem]': isVertical,
                  },
                )}
              >
                {item.title}
              </h1>

              <h2
                className={clsx('text-gray-900', {
                  'mt-4 text-lg xl:text-xl': isHorizontal,
                  'md:my-12': !item.signupHeader,
                  'lg:my-12': item.signupHeader,
                  'mt-8 text-lg md:text-xl xl:mt-16 xl:text-3xl': isVertical,
                })}
              >
                {item.subtitle}
              </h2>
            </div>
            {item.buttons.length > 0 && (
              <ButtonGroup
                buttons={item.buttons}
                className={clsx({
                  'relative z-10 justify-self-start sm:static md:col-start-1 md:row-start-2':
                    isHorizontal, 'justify-center mx-auto my-6 md:my-0': isVertical
                })}
              />
            )}
          </div>
          {item.signupHeader && item.threeJs ? (
            <div className="flex">
              <SignupForm />
              <ThreeJs threeJsScript={item.threeJs} />
            </div>
          ) : (
            ''
          )}
          {!item.signupHeader &&
            (item.lottieFile || item.image?.url || item.threeJs) && (
              <div
                className={clsx({
                  'justify-self-end max-h-56 max-w-56 md:row-span-2 md:max-h-none md:max-w-none md:justify-self-auto':
                    isHorizontal,
                })}
              >
                {item.threeJs ? (
                  <ThreeJs threeJsScript={item.threeJs} />
                ) : item.lottieFile && isHorizontal ? (
                  <Lottie lottieFile={item.lottieFile} />
                ) : (
                  <img
                    src={item.image?.url}
                    alt={item.image?.alt}
                    className={clsx({
                      'max-h-56 max-w-56 md:max-h-none md:max-w-none':
                        isHorizontal,
                    })}
                  />
                )}
              </div>
            )}
        </div>
      </div>
    )
  })
}

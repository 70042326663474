import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart as RechartsPieChart,
} from 'recharts'

import { Circle } from 'lucide-react'

import { type PolarViewBox } from 'recharts/types/util/types'
import { Card, CardContent, CardHeader, CardTitle } from '../../ui/card'
import { type ChartConfig, ChartContainer } from '../../ui/chart'
import { formatMoney, formatPercent } from '../../../utils'
import { ChangeDirection, type ChangeDirectionType } from './change-direction'
import React, { useState } from 'react'

export type ChartDataType = {
  name: string
  value: number
}

export const PieChart = ({
  title,
  changePercentage,
  changeDirection,
  data,
  centerData,
  centerTitle,
  valueFormatter = (value: number) => formatMoney(value),
}: {
  title: string
  changePercentage: number
  changeDirection: ChangeDirectionType
  data: ChartDataType[]
  centerData?: string
  centerTitle?: string
  valueFormatter?: (value: number) => string
}) => {
  const [activeIndex, setActiveIndex] = useState(20)
  const sortedData = [...data].sort((a, b) => b.value - a.value)

  // Define gradient IDs based on title
  const gradientIds = {
    AssetsbyClass: [
      '#2452BC/#18367C',
      '#547DDE/#2452BC',
      '#7F9EE6/#547DDE',
      '#B2C5F0/#7F9EE6',
      '#DDE5F8/F2F5FD',
    ],
    Transactions: [
      '#8621AB/#651980',
      '#B954DE/#8621AB',
      '#CB7FE6/#B954DE',
      '#DCAAEE/#F8EAF1',
    ],
  }

  const chartType = title.replaceAll(' ', '') as keyof typeof gradientIds
  const gradients = gradientIds[chartType] || []

  return (
    <Card className="mx-auto flex h-full flex-col border border-gray-700 bg-transparent">
      <CardHeader className="p-4 text-white">
        <div className="flex justify-between">
          <div className="flex items-center gap-x-2">
            <CardTitle className="font-medium md:text-lg">{title}</CardTitle>
            <ChangeDirection
              changeDirection={changeDirection}
              changePercentage={changePercentage}
            />
          </div>
          <p className="text-sm font-normal text-gray-500">30d</p>
        </div>
      </CardHeader>
      <CardContent className="my-auto p-0">
        <ChartContainer
          config={{} satisfies ChartConfig}
          className="aspect-[3/2] h-fit p-2 min-[500px]:p-6 sm:p-2 md:p-3 min-[1200px]:p-4 [&_.recharts-pie-label-text]:fill-foreground"
        >
          <RechartsPieChart>
            <defs>
              {gradients.map((colors, index) => {
                const [startColor, endColor] = colors.split('/')
                return (
                  <linearGradient
                    key={index}
                    id={`myGradient${index}${chartType}`}
                  >
                    <stop offset="0%" stopColor={startColor} />
                    <stop offset="100%" stopColor={endColor} />
                  </linearGradient>
                )
              })}
            </defs>
            <Pie
              data={sortedData}
              innerRadius={'66%'}
              outerRadius={'100%'}
              cornerRadius={0}
              fill="#8884d8"
              paddingAngle={0}
              dataKey="value"
              nameKey="name"
              legendType="circle"
              onMouseEnter={(_, index) => setActiveIndex(index)}
              onMouseLeave={_ => setActiveIndex(20)}
            >
              {data.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={`url(#myGradient${index}${title.replaceAll(' ', '')})`}
                  className={`stroke-transparent stroke-0 ${
                    activeIndex === index ? 'stroke-white stroke-1' : ''
                  }`}
                />
              ))}
              {centerData && centerTitle && (
                <Label
                  width={30}
                  position="center"
                  content={props => {
                    const { cx, cy } = props.viewBox as PolarViewBox
                    return (
                      <>
                        <text
                          x={cx}
                          y={cy}
                          fill="#FFFFFF"
                          className="recharts-text recharts-label"
                          textAnchor="middle"
                          dominantBaseline="central"
                        >
                          <tspan
                            alignmentBaseline="middle"
                            fontSize="28"
                            fontWeight="bold"
                          >
                            {centerData}
                          </tspan>
                        </text>
                        <text
                          x={cx}
                          y={Number(cy) + 20}
                          fill="#B6BEC9"
                          className="recharts-text recharts-label flex"
                          textAnchor="middle"
                          dominantBaseline="central"
                        >
                          <tspan fontSize="12" fontWeight="medium">
                            {centerTitle}
                          </tspan>
                        </text>
                      </>
                    )
                  }}
                ></Label>
              )}
            </Pie>
            {/* Update Legend content */}
            <Legend
              verticalAlign="middle"
              align="right"
              layout="vertical"
              wrapperStyle={{ paddingLeft: '0.75rem' }}
              content={props => (
                <div className="flex flex-col gap-y-3">
                  {props.payload
                    ?.sort(
                      (a, b) =>
                        Number(b.payload?.value) - Number(a.payload?.value),
                    )
                    .map(({ value, payload }, index) => (
                      <div
                        className={`flex items-center gap-x-1`}
                        key={`item-${index}`}
                        onMouseEnter={() => setActiveIndex(index)}
                        onMouseLeave={_ => setActiveIndex(20)}
                      >
                        <Circle
                          className={`size-4 stroke-transparent stroke-0 ${
                            activeIndex === index ? 'stroke-white stroke-1' : ''
                          }`}
                          style={{
                            fill: `url(#myGradient${index}${title.replaceAll(' ', '')})`,
                          }}
                        />
                        <div className="flex flex-col gap-y-px">
                          <p
                            className={`text-xs font-medium text-white [&.active]:font-bold ${
                              activeIndex === index ? 'active' : ''
                            }`}
                          >
                            {value}
                          </p>
                          <p className="text-xs text-gray-500">
                            {`${valueFormatter(Number(payload?.value))} (${formatPercent(Number((payload as unknown as { percent: string })?.percent))})`}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            />
          </RechartsPieChart>
        </ChartContainer>
      </CardContent>
    </Card>
  )
}

export const formatMoney = (amount: number, decimals = 2) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    maximumFractionDigits: decimals,
  }).format(amount)

export const formatMoneyOneDigit = (amount: number, decimals = 1) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    maximumFractionDigits: decimals,
  }).format(amount)

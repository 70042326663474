import React from 'react'
import { UseFormRegister, FieldValues, FieldErrors } from 'react-hook-form'
import clsx from 'clsx'

export const Input = ({
  register,
  name,
  required,
  errors,
  label,
  placeholder,
  type,
  className = 'p-3 w-full rounded-lg border border-gray-900',
}: {
  register: UseFormRegister<FieldValues>
  name: string
  required: boolean
  errors: FieldErrors<FieldValues>
  label: string
  placeholder?: string
  type: string
  className?: string
}) => (
  <input
    className={clsx(className)}
    {...register(name, {
      required,
    })}
    placeholder={placeholder || `Enter your ${label}`}
    type={type}
    aria-invalid={errors[name] ? 'true' : 'false'}
  />
)

import React from 'react'
import { TopBarLogo } from '.'
// @ts-ignore
import hamburger from '../../../images/Hamburger.png'

export const TopBarMobileMenu = ({
  show,
  setShow,
  setSubmenu,
}: {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  setSubmenu: React.Dispatch<React.SetStateAction<string>>
}) => (
  <div className={`flex w-full items-center justify-between`}>
    <TopBarLogo />
    <div>
      <img
        className="cursor-pointer"
        src={hamburger}
        alt={'Hamburger Menu Icon'}
        onClick={e => {
          e.stopPropagation()
          if (show) {
            setShow(false)
            setSubmenu('')
          } else {
            setShow(true)
          }
        }}
      />
    </div>
  </div>
)

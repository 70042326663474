import React, { Fragment } from 'react'
import { StructuredText, renderNodeRule } from 'react-datocms'
import {
  isCode,
  isHeading,
  isParagraph,
  isList,
} from 'datocms-structured-text-utils'
import { GatsbyImage } from 'gatsby-plugin-image'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { SmartLink, VideoEmbed } from '..'
import clsx from 'clsx'

const H2 = ({ children }: { children: any }) => (
  <h2 className="my-12 text-4xl font-medium text-gray-900">{children}</h2>
)

const H3 = ({ children }: { children: any }) => (
  <h3 className="my-6 text-xl font-normal text-gray-600">{children}</h3>
)

// TODO: this needs to handle more components
const getChild = (n: any) => {
  switch (n.type) {
    case 'paragraph': {
      return <p className="my-6 text-grey-800">{n.children.map(getChild)}</p>
    }

    case 'link': {
      const props = n?.meta?.reduce(
        (acc: any, m: { id: string; value: string }) => ({
          ...acc,
          [m.id]: m.value,
        }),
        {},
      )

      return (
        <SmartLink
          {...props}
          key={n.url}
          className="text-blue-600 no-underline hover:text-blue-800"
          linkTo={n.url}
        >
          {n.children.map(getChild)}
        </SmartLink>
      )
    }
    case 'span': {
      if (n?.marks?.length) {
        const classNames: Array<string> = n.marks.map((mark: string) => {
          switch (mark) {
            case 'code':
              return 'py-1 px-2 text-green-400 bg-grey-800 font-mono rounded'
            case 'emphasis':
              return 'italic'
            case 'highlight':
              return 'bg-yellow-400 rounded px-1'
            case 'strikethrough':
              return 'line-through'
            case 'strong':
              return 'font-bold'
            case 'underline':
              return 'underline'
          }
        })

        return (
          <span key={n.value} className={classNames.join(' ')}>
            {n.value}
          </span>
        )
      }
    }
    default:
      return n.value
  }
}

const P = ({ node }: { node: { children: Array<any> } }) => (
  <p className="my-6 text-grey-800">{node.children.map(getChild)}</p>
)

const Code = ({ code, language }: { code: string; language: string }) => {
  return (
    <SyntaxHighlighter
      className="my-6 rounded"
      language={language}
      style={nightOwl}
      wrapLongLines
    >
      {code}
    </SyntaxHighlighter>
  )
}

const List = ({ node }: { node: { children: Array<any>; style: string } }) => {
  const isOrdered = node.style === 'numbered'
  const classeNames = clsx('ps-10', {
    'list-disc': !isOrdered,
    'list-decimal': isOrdered,
  })

  const listItems = () => (
    <>
      {node.children.map((child, index) => (
        <li key={index} className="my-6 text-gray-800">
          {child.children.map((c: any, i: number) => (
            <Fragment key={i}>{getChild(c)}</Fragment>
          ))}
        </li>
      ))}
    </>
  )

  if (isOrdered) {
    return <ol className={classeNames}>{listItems()}</ol>
  }

  return <ul className={classeNames}>{listItems()}</ul>
}

export const BlogContent = ({ content }: { content: any }) => {
  return (
    <div className="mx-4 mb-40">
      <StructuredText
        data={content}
        customNodeRules={[
          renderNodeRule(isHeading, ({ children, key, node }) => {
            switch (node.level) {
              case 2: {
                return <H2 children={children} key={key} />
              }
              case 3: {
                return <H3 children={children} key={key} />
              }
              default: {
                return <H3 children={children} key={key} />
              }
            }
          }),
          renderNodeRule(isCode, ({ children, key, node, ...rest }) => (
            <Code code={node.code} language={node.language || ''} key={key} />
          )),
          renderNodeRule(isParagraph, ({ ancestors, children, key, node }) => {
            return <P node={node} key={key} />
          }),
          renderNodeRule(
            isList,
            ({ ancestors, children, key, node, ...rest }) => {
              return <List node={node} key={key} {...rest} />
            },
          ),
        ]}
        renderBlock={({ record }: { record: any }) => {
          // return null
          switch (record.__typename) {
            case 'DatoCmsImage':
              return (
                <GatsbyImage
                  className="mx-auto flex max-w-[800px] items-center"
                  image={record.responsiveImage.gatsbyImageData}
                  alt={
                    record.responsiveImage.alt ||
                    record.responsiveImage.filename
                  }
                />
              )
            case 'DatoCmsVideo':
              return (
                <VideoEmbed
                  className="mx-auto flex max-w-[800px] items-center"
                  video={record.externalVideo}
                />
              )
            default:
              return null
          }
        }}
      />
    </div>
  )
}
